import React, { useEffect } from "react";
import localRepository from "../../localRepository";
import qs from "query-string";
import { useAuth0 } from "../Auth0/useAuth";
import * as api from "api";
import LoadingView from "components/styled/loadingView/LoadingView";
import { useLoggerContext } from "contexts/logger/logger.context";
import getMGUser from "libs/getMGUser";
import jwt_decode from "jwt-decode";

export const External = (props) => {
  const { authState, logout, setSession } = useAuth0();
  const { logger } = useLoggerContext();

  useEffect(() => {
    const { accessToken, idToken } = qs.parse(props.location.search) || {};
    if (!accessToken) logout();

    const metadata = jwt_decode(accessToken);
    const { euroUserId, preferred_username, email, sub } = getMGUser(metadata);
    api
      .getUserExists(euroUserId, email, sub, preferred_username)
      .then(async ({ usuarioExistente }) => {
        if (usuarioExistente) {
          localRepository.accessToken.set(accessToken);
          setSession({
            expiresIn: metadata.exp - Date.now() / 1000,
            accessToken,
            idToken,
          });
        }
      })
      .catch((error) => {
        logger.error(
          `[External] [api.getUserExists] - Error en migración desde Euro!`,
          { error, authState }
        );
        setTimeout(logout, 2000);
      });
  }, []);

  return <LoadingView />;
};
